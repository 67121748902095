import React from 'react'

export default function Footer() {

    const year = new Date().getFullYear()

    return (
        <>
            <center>
            <p style={{color:'#FFFFFF', padding:20, fontSize:16}}>Survial Swim Club {year} All rights reserved.</p>
            </center>
        </>
    )
}
