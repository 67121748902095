import {notification, Select} from "antd";
const {Option} = Select

export function SelectTime({title,disabled,value,setValue}){

    const time =[
        "5-6",'5:30-6:30','6-7','6:30-7:30','7-8','7:30-8:30','8-9','8:30:-9:30','9-10',
        '9:30:-10:30','10-11','10:30:-11:30','11-12','11:30-12:30','12-13','12:30-13:30','13-14',
        '13:30-14:30','14-15','14:30-15:30','15-16','15:30-16:30','16-17','16:30-17:30',
        '17-18','17:30-18:30','18-19','18:30-19:30','19-20','19:30-20:30','20-21','20:30-21:30',
    ]

    return(
        <Select
            // showSearch
            placeholder={title}
            style={{width:'100%'}}
            // defaultActiveFirstOption={false}
            // filterOption={false}
            // onChange={(e)=>setValue(e)}
            // notFoundContent={null}
            // disabled={disabled}
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //     option.children.indexOf(input) >= 0
            // }
            // filterSort={(optionA, optionB) =>
            //     optionA.children.localeCompare(optionB.children)
            // }
            
        >
            {
                time?.map(e=>(
                    <Option key={e} value={e}>{e}</Option>
                ))
            }
        </Select>
    )
}

export function SelectClass({title,disabled,value,setValue}){

    const classD =['Private','Joining','Outdoor']

    return(
        <Select
            showSearch
            value={value}
            placeholder={title}
            style={{width:'100%'}}
            defaultActiveFirstOption={false}
            onChange={(e)=>setValue(e)}
            // notFoundContent={null}
            disabled={disabled}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children.localeCompare(optionB.children)
            }
            
        >
            {
                classD?.map(e=>(
                    <Option key={e} value={e}>{e}</Option>
                ))
            }
        </Select>
    )
}

export const openSuccessNotification = (data) => {
    notification.success({
      message: `${data.title}`,
      description:data.message
    });
};

export const openErrorNotification = (data) => {
    notification.error({
      message: `${data.title}`,
      description:data.message
    });
};

export const openWarningNotification = (data) => {
    notification.warning({
      message: `${data.title}`,
      description:data.message
    });
};