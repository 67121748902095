import { Button, Card, Input,Image, Form } from 'antd'
import React, { useState } from 'react'
import Logo from '../assets/logo-ssc.png'
import { openErrorNotification, openSuccessNotification } from '../function/own-comp'
import { loginProccess } from '../function/fn'



export default function Login({setAuth}) {

    const [form] = Form.useForm()
    const [username,setUsername] =useState("")
    const [password,setPassword] = useState("")
    const [loading,setLoading]=useState(false)

    const handleLogin= async (value)=>{
        const data={
            username: value?.username,
            password: value?.password
        }
        let state = await loginProccess(data)
        setAuth(state)
        if(!state){
            setLoading(false)
        }
    }

    const onFinish=(values)=>{
        setLoading(true)
        handleLogin(values)

    }

    const onFinishFailed=()=>{
        console.log("")
    }

    return (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',width:'100%'}}>
            <Card
                style={{margin:'0 auto',maxWidth:400,textAlign:'center', minWidth:350}}
            >
                <Form
                    form={form}
                    name="login"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                <Image src={Logo} style={{width:200}} preview={false} />
                <br/>
                <br/>
                <p>
                    <h3>Please login</h3>
                </p>
                <p>
                <Form.Item
                    name="username"
                    rules={[{required:true, message:'Please input username'}]}
                >
                    <Input size="large" style={{textAlign:'center'}} onChange={(e)=>setUsername(e.target.value)} placeholder="username" />
                </Form.Item>
                </p>
                <p>
                <Form.Item
                    name="password"
                    rules={[{required:true, message:'Please input password'}]}
                >
                    <Input.Password size="large" className="login-pass" onChange={(e)=>setPassword(e.target.value)}  placeholder="password" />
                </Form.Item>
                </p>
                <p>
                <Button
                    htmlType="submit"
                    loading={loading}
                    size="large"
                >
                    Login
                </Button>
                </p>
                {/* <p>
                    Forgot your password? please contact your admin.
                </p> */}
                </Form>
            </Card>
        </div>
    )
}
