import React, { useEffect, useState } from 'react'
import {Button, Card, Col, Image, Row} from 'antd'
import Logo from '../assets/logo-ssc.png'
import { useHistory } from 'react-router'
import LogoutModal from '../components/logout/modal/LogoutModal'
import { getCookie } from '../function/fn'

export default function Header({setAuth}) {

    const [isMobile,setIsMobile] = useState(false)
    const [openLogout,setOpenLogout] = useState(false)
    const history = useHistory()

    let instName = getCookie("USER_LOGGED") ? JSON.parse(getCookie("USER_LOGGED"))?.inst_name : ''
    
    const handleResize =()=>{
        if(window.innerWidth <=960){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    useEffect(()=>{
        handleResize()
    },[])

    window.addEventListener('resize',handleResize)

    const handleLogout =()=>{
        setOpenLogout(true)
    }

    return (
        <>
            <LogoutModal open={openLogout} setOpen={setOpenLogout} setAuth={setAuth} />
            <Card
                style={{margin:'0 auto',width: isMobile ? '100%':'50%'}}
            >
                <center>
                    <Image src={Logo} style={{width:300}} preview={false} />
                    <p>
                        <h3>Logged in as {instName?.toUpperCase()}</h3>
                        
                    </p>
                    <p style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10}}>
                       
                        <Button onClick={()=>history.push("/list")} size="large" >
                            List
                        </Button>&emsp;
                        <Button type="danger" size="large" onClick={()=>handleLogout()}>
                            log out
                        </Button>
                           
                    </p>
                </center>
            </Card>
        </>
    )
}
