import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React from 'react'
import { delete_cookie } from '../../../function/fn'

export default function LogoutModal({open,setOpen,setAuth}) {

    const handleLogout =()=>{
        delete_cookie("USER_LOGGED")
        setAuth(false)
    }

    const handleCancel =()=>{
        setOpen(false)
    }

    return (
        <>
            <Modal title={<b><ExclamationCircleOutlined style={{color:'gold'}} /> Logout</b>} visible={open} onOk={handleLogout} onCancel={handleCancel}>
                <p>Do you wanna logout?</p>
             
            </Modal>
        </>
    )
}
