import Cookies from "js-cookie";
import { openErrorNotification, openSuccessNotification } from "./own-comp";

const axios = require("axios");
export const loginProccess = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("username", data?.username);
  params.append("password", data?.password);

  return await axios
    .post(
      "https://survivalswimclub.com/mysystem/login/loginProccess.php",
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "Incorrect username or password" &&
        (await response?.data) !== "notUser"
      ) {
        openSuccessNotification({ title: "Login successful!", message: null });
        setCookie("USER_LOGGED", JSON.stringify(response?.data), 1);
        // sessionStorage.setItem("USER_LOGGED",JSON.stringify(response?.data))
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const isUser = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("username", data?.username);
  params.append("inst_id", data?.inst_id);

  return await axios
    .post(
      "https://survivalswimclub.com/mysystem/login/loginProccess.php",
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "Incorrect username or password" &&
        (await response?.data) !== "notUser"
      ) {
        return true;
      } else {
        // openErrorNotification({title:'Not user',message:null})
        delete_cookie("USER_LOGGED");
        return false;
      }
    });
};

export const getTimesheet = async (data, date) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("username", data?.username);
  params.append("inst_id", data?.inst_id);

  params.append("startDate", date?.from);
  params.append("endDate", date?.to);

  // wait ...
  await randomFn();

  return await axios
    .post("https://survivalswimclub.com/mysystem/get/getTimesheet.php", params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export function currencyFormat(num) {
  num = num > 0 ? parseFloat(num) : 0;
  return "$" + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getTimesheetWithPagination = async (page) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("page", page);

  // wait ...
  await randomFn();

  // params.append('username',data?.username)
  // params.append('user_id',data?.inst_id)

  // params.append('startDate',date?.from)
  // params.append('endDate',date?.to)

  return await axios
    .post(
      "https://survivalswimclub.com/mysystem/get/getTimesheetWithPagination.php",
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export function getCookie(cname) {
  return Cookies.get(cname);
}

export function delete_cookie(name) {
  Cookies.remove(name);
}

export function setCookie(c_name, value, exdays) {
  Cookies.set(c_name, value, { expires: exdays });
}

export const randomFn = async () => {
  // const rndInt = Math.floor(Math.random() * (50 - 35 + 1)) + 35;
  // await new Promise((resolve) => setTimeout(resolve, rndInt * 1000));
};
