import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import CreateTimesheet from './page/CreateTimesheet';
import SuccessPage from './page/SuccessPage';
import Header from './dynamic/header';
import Footer from './dynamic/footer';
import { useEffect, useState } from 'react';
import Login from './page/Login';
import { getCookie } from './function/fn';

function App() {

  let getuser = getCookie("USER_LOGGED");
  
  const [auth,setAuth] = useState(getCookie("USER_LOGGED") ? true : false)
  const [login ,setLogin]=useState(false)

  useEffect(() => {
    const data = getCookie("USER_LOGGED") ? JSON.parse(getCookie("USER_LOGGED")) : null
    if(data!==undefined && data!==null){
      setLogin(true)
    }else{
      setLogin(false)
    }
  }, [auth])

  return (
    <div className="App" >
      {/* basename="/mysystem/timesheet" */}
        <Router>
          {login ===true ?
          <>
          <Header setAuth={setAuth} />
            <Switch >

              <Route exact path="/">
                <CreateTimesheet />
              </Route>
              <Route exact path="/list">
                <SuccessPage />
              </Route>
                  
            </Switch>
          <Footer /> 
          </>
          :
          <>
          <Switch >
            <Route path="/" >
              <Login setAuth={setAuth} />
            </Route>
          </Switch>
          </>
          }
        </Router>
    </div>
  );
}

export default App;
