import { Affix, Button, Card, Col, DatePicker, Divider, Row,Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import timesheetCol from '../components/listtimesheet/tableColumn/timesheetCol'
import { getTimesheet, isUser,getTimesheetWithPagination, currencyFormat, getCookie } from '../function/fn'
import moment from 'moment'
import { SolutionOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import ClickNHold from 'react-click-n-hold'; 

export default function SuccessPage() {

    const [isMobile,setIsMobile] = useState(false)
    const [dataList,setDataList] = useState({})
    const [loading,setLoading] = useState(true)
    const [name,setName] = useState("")

    const [sumAmount,setSumAmount] = useState(0)
    const [sumUnpaid,setSumUnpaid] = useState(0)
    const [sumPaid,setSumPaid] = useState(0)

    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)

    const [range,setRange] = useState({
        from:"",
        to:""
    })

    const history = useHistory()

    const handleResize =()=>{
        if(window.innerWidth <=960){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    useEffect(()=>{
        handleResize()
    },[])

    window.addEventListener('resize',handleResize)

    useEffect(() => {
        async function checkUser(){
            if(!await isUser(JSON.parse(getCookie("USER_LOGGED")))){
                window.location.reload()
            }
        }
        checkUser()
    }, [])

    useEffect(()=>{
        async function fetchData(){
            const data = JSON.parse(getCookie("USER_LOGGED"))
            setName(data?.inst_name)
            
            let dataRecord = await getTimesheet(data,range)

            setDataList(dataRecord)
            setSumAmount(dataRecord?.sumAmount)
            setSumPaid(dataRecord?.sumPaid)
            setSumUnpaid(dataRecord?.sumUnpaid)
            setLoading(false)
        }
        fetchData()
    },[range])

    const handleSelectDate=()=>{
        if(startDate !== null && endDate !==null){
            // console.log('test')
            setLoading(true)
            setRange({
                from: moment(startDate).format("YYYY-MM-DD").toString(),
                to:moment(endDate).format("YYYY-MM-DD").toString(),
            })
        }else{
            setLoading(true)
            setRange({
                from:"",
                to:""
            })
        }
    }

    useEffect(()=>{
        if(startDate !== null && endDate !==null){
            handleSelectDate()
        }

        if(startDate === null && endDate ===null){
            handleSelectDate()
        }
    },[startDate,endDate])

    const handleSetStartDate=(e)=>{
        setStartDate(e)
    }

    const handleSetEndDate=(e)=>{
        setEndDate(e)
    }

    return (
        <>
            <Row>
                <Col xs={24}>
                    <Card
                        style={{margin:'0 auto',width: isMobile ? '100%':'50%'}}
                    >
                        <Affix offsetTop={10}>
                            <p className="header-timesheet">

                                TIMESHEET {
                                range?.from!== "" && range?.to!== "" ? 
                                    moment(range?.from).format("DD/MM/YYYY")+" - "+moment(range?.to).format("DD/MM/YYYY")
                                : 
                                    moment(new Date).format("DD/MM/YYYY")
                            }
                        </p>
                        </Affix>
                        <p style={{fontSize:18}}>
                            <Row>
                                <Col xs={24} md={12} style={{padding:10}}>
                                    <SolutionOutlined style={{color:'green'}} /> Instructor: <b>{name}</b>
                                </Col>
                                <Col xs={12} md={6} style={{padding:10}}>
                                    <DatePicker 
                                        size="large" 
                                        inputReadOnly
                                        placeholder="from" 
                                        style={{width:'100%'}} 
                                        onChange={(e)=> handleSetStartDate(e)} 
                                    />
                                </Col>
                                <Col xs={12} md={6} style={{padding:10}}>
                                    <DatePicker 
                                        inputReadOnly
                                        size="large" 
                                        placeholder="to" 
                                        style={{width:'100%'}} 
                                        onChange={(e)=> handleSetEndDate(e)}
                                    />
                                    {/* <RangePicker onChange={(e)=> handleSelectDate(e)} placeholder={['from','to']} style={{width:'100%'}} /> */}
                                </Col>
                            </Row>
                        </p>

                        <Table
                            showHeader={false}
                            className="table-custom-list"
                            columns={timesheetCol()}
                            dataSource={dataList?.data}
                            rowKey={record => record?.ts_id}
                            pagination={false}
                            sticky 
                            loading={loading}
                            scroll={{ x: 200 }} 
                        />      
                        <br/>
                        <p style={{fontSize:18,textAlign:'right',padding:'3px 10px'}}>
                            Total : {currencyFormat(sumAmount)}
                        </p>
                        <p style={{fontSize:18,textAlign:'right',padding:'3px 10px'}}>
                            Total paid : {currencyFormat(sumPaid)}
                        </p>
                        <p style={{fontSize:18,textAlign:'right',padding:'3px 10px'}}>
                            Total pending : {currencyFormat(sumUnpaid)}
                        </p>
                        <p>
                            <Button type="primary" style={{width:'100%'}} onClick={()=> history.push("/")} size="large" htmlType="submit">
                                Add more +
                            </Button>
                        </p>
                        {/* <p>
                            <ClickNHold 
                                time={2} // Time to keep pressing. Default is 2
                                onStart={()=>console.log('start')} // Start callback
                                onClickNHold={()=> console.log('time out')} //Timeout callback
                                onEnd={()=> console.log('end')} >
                                    <button>Click and hold</button>
                            </ClickNHold>
                        </p> */}
                    </Card>
                </Col>
                
            </Row>
        </>
    )
}
