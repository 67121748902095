import { Card, Col, Row ,Form, Input,Select, Button, Divider , Radio,} from 'antd'
import React, { useEffect, useState } from 'react'
import {MinusCircleOutlined} from '@ant-design/icons'
import { getCookie, isUser } from '../function/fn'
import { useHistory } from 'react-router'
import { openErrorNotification, openSuccessNotification } from '../function/own-comp'

const {Option} = Select
const {TextArea} = Input

const axios = require('axios')

export default function CreateTimesheet() {

    const [form] = Form.useForm()
    const [isMobile,setIsMobile] = useState(false)
    const [loading,setLoading] = useState(false)
    const history = useHistory()

    const [forType,setForType] = useState("")

    useEffect(() => {
        async function checkUser(){
            if(!await isUser(JSON.parse(getCookie("USER_LOGGED")))){
                window.location.reload()
            }
        }
        checkUser()
    }, [])
     
    const [data,setData] = useState([])

    const handleResize =()=>{
        if(window.innerWidth <=960){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }
    }

    useEffect(()=>{
        handleResize()
    },[])

    window.addEventListener('resize',handleResize)

    const onForTypeCheck =(e)=>{
        setForType(e)
    }

    const insertTimesheet = async (data) => {
        const inst_id = JSON.parse(getCookie("USER_LOGGED"))?.inst_id

        const params = new URLSearchParams();
        params.append('db_user', process.env.React_App_DB_USER);
        params.append('db_password',process.env.React_App_DB_PASSWORD);
        params.append('db', process.env.React_App_DB);

        params.append('data',JSON.stringify(data))

        params.append('inst_id',inst_id);

        await axios.post(
            'https://survivalswimclub.com/mysystem/insert/insertTimesheet.php',  params
            )
            .then(async function (response) {
                if(await response?.data ==='success'){
                    form.resetFields()
                    openSuccessNotification({title:'Submit successfully!',message:null})
                    history.push("/list")
                    setLoading(false)
                }else{
                    openErrorNotification({title:'Failed',message:response.data})
                    setLoading(false)
                }
        })
    }

    const onFinish = (values) => {
        setLoading(true)
        insertTimesheet(values?.class)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const time = () =>{
        let data =[]
        for(let i=6;i<=20;i++){
      
            for(let j=0 ;j< 4;j++){
                let min = j <= 1 ? '00':'30'
                let minTo = j === 0 || j===3 ? '30': '00'

                let hourTo = j>0 ? i+1:i

                let time = `${i}:${min}-${hourTo}:${minTo}`
 
                data.push(<Option key={time} value={time}>{time}</Option>)
            }
            
        }

        return data.sort();
        
    }  
    
    return (
        <>
            <Row>
                <Col xs={24}>
                    <Card
                        style={{margin:'0 auto',width: isMobile ? '100%':'50%',minHeight:'50vh'}}
                    >
                        
                        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                            <Form.List name="class" >
                            {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField },index) => (
                                <>

                                    <Row>  
                                        <p style={{fontSize:20}}><MinusCircleOutlined style={{fontSize:18}} className="remove-class zoom-bigger" onClick={() => remove(name)} /> -&emsp; {index+1}</p>
                                        <Col xs={24}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'className']}
                                                fieldKey={[fieldKey, 'className']}
                                                rules={[{ required: true, message: 'Missing class name!' }]}
                                            >
                                                <Select placeholder="Class name" size="large" style={{width:'100%'}}>
                                                    <Option key="Private (Indoor)" value="Private (Indoor)">Private (Indoor)</Option>
                                                    <Option key="Private (Outdoor)" value="Private (Outdoor)">Private (Outdoor)</Option>
                                                    <Option key="Join (2-3pax)" value="Join (2-3pax)">Join (2-3pax)</Option>
                                                    <Option key="Join (4-5pax)" value="Join (4-5pax)">Join (4-5pax)</Option>

                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'time']}
                                                fieldKey={[fieldKey, 'time']}
                                                rules={[{ required: true, message: 'Missing time!' }]}
                                            >
                                                <Select 
                                                    // showSearch
                                                    size="large"
                                                    placeholder="Time" 
                                                    style={{width:'100%'}}
                                                    defaultActiveFirstOption={false}
                                                    
                                                >
                                                    {
                                                        time()
                                                    }       
                                                </Select>
                                                
                                            </Form.Item>
                                            
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'forType']}
                                                fieldKey={[fieldKey, 'forType']}
                                                rules={[{ required: true, message: 'Missing class type!' }]}
                                            >

                                                <Radio.Group onChange={(e)=>onForTypeCheck(e)} value={forType} style={{width:'100%'}}>
                                                    <Row style={{width:'100%'}}>
                                                        <Col xs={12} lg={5} style={{padding:10}}>
                                                            <Radio value="Infant">Infant</Radio>
                                                        </Col>
                                                        <Col xs={12} lg={5} style={{padding:10}}>
                                                            <Radio value="Toddler" >Toddler</Radio>
                                                        </Col>
                                                        <Col xs={12} lg={5} style={{padding:10}}>
                                                            <Radio value="Kid">Kid</Radio>
                                                        </Col>
                                                        <Col xs={12} lg={5} style={{padding:10}}>
                                                            <Radio value="Adult" >Adult</Radio>
                                                        </Col>
                                                        <Col xs={12} lg={4} style={{padding:10}}>
                                                            <Radio value="Autism" >Autism</Radio>
                                                        </Col>
                                                    </Row>
                                                </Radio.Group>
                                                
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'ts_remark']}
                                                fieldKey={[fieldKey, 'ts_remark']}
                                            >

                                                <TextArea size="large" placeholder="remark..." allowClear />
                                                
                                            </Form.Item>

                                            <Divider />
                                        </Col>

                                        
                                    </Row>   

                                </>
                                ))}
                                
                                <Form.Item>
                                <Button type="dashed" size="large" onClick={() => add()} block >
                                    Add Class +
                                </Button>
                                </Form.Item>
                                
                            </>
                            )}
                            </Form.List>
                            <Form.Item>
                                {/* <Space size="middle"> */}
                                <Button type="primary" style={{width:'100%'}} loading={loading} size="large" htmlType="submit">
                                    Submit
                                </Button>

                                {/* </Space> */}
                            </Form.Item>
                        </Form>

                    </Card>
                </Col>

                            

                
            </Row>
        </>
    )
}
