import { CheckCircleOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react'
import { currencyFormat } from '../../../function/fn';

export default function timesheetCol() {
    
    var array = [
        {
          title: 'no',
          dataIndex: 'no',
          key: 'no',
          width:50,
          render: (text,record,i)=>(
            <span>
              <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
                {i+1} :
              </Tooltip>
            </span>
          )
        },
        {
          title: 'Class name',
          dataIndex: 'classType',
          key: 'classType',
          width:150,
          render:(text,record)=>(
            <span>
              <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
                  {record?.classType}
              </Tooltip>
            </span>
          )
        },
        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
          width:100,
          render:(text,record)=>(
            <span>
              <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
                  {record?.time}
              </Tooltip>
            </span>
          )
        },
        {
          title: 'Amount',
          dataIndex: 'ts_price',
          key: 'ts_price',
          width:60,
          render: (text,record)=>(
            <span>
              <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
                <div style={{fontSize:14}} className={record?.ts_payment === 'pending' ? "pending-badge":"success-badge"}>
                  { currencyFormat(record?.ts_price)}
                </div>
              </Tooltip>
            </span>
          )
        },

        // {
        //   title: 'State',
        //   dataIndex: 'ts_payment',
        //   key: 'ts_payment',
        //   width:50,
        //   render: (text,record)=>(
        //     <span>
        //       <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
        //         { record?.ts_payment === 'pending' ? <div className="pending-badge">P</div>: <CheckCircleOutlined className="paid-badge" />}
        //       </Tooltip>
        //     </span>
        //   )
        // },
        
        // {
        //   title: 'Tags',
        //   key: 'tags',
        //   dataIndex: 'tags',
        //   render: tags => (
        //     <>
        //       {tags.map(tag => {
        //         let color = tag.length > 5 ? 'geekblue' : 'green';
        //         if (tag === 'loser') {
        //           color = 'volcano';
        //         }
        //         return (
        //           <Tag color={color} key={tag}>
        //             {tag.toUpperCase()}
        //           </Tag>
        //         );
        //       })}
        //     </>
        //   ),
        // },
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (text, record) => (
        //     <Space size="middle">
        //       <a>Invite {record.name}</a>
        //       <a>Delete</a>
        //     </Space>
        //   ),
        // },
      ];

    return array;
}
